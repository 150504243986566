import moment from "moment/moment";
import React, {useState} from "react";
import MyBetSingleItem from "./MyBetSingleItem";
import html2pdf from "html2pdf.js/src";
import {translateText} from "../../services/translation";


const MyBetSingleSlip = ({ el2, modalData }) => {
    const [slipOpen, setSlipOpen] = useState(false)
    const [cashOutAmount, setCashOutAmount] = useState(null)

    const {
        id,
        betAmount,
        isCancellable,
        isCancellableTill,
        systemValue,
        winningAmount,
        winningChance,
        amountWon,
        userID,
        betDate
    } = el2;

    // const getCashOutAmount = async () => {
    //     try {
    //         const response = await axios.get(`https://api.prady.in/api/cashoutamount?betId=${id}`).then(
    //             (res) => {
    //                 setCashOutAmount(res.data)
    //                 if (res.data?.value === 0){
    //                     dispatch(setMsgForModal(`Cashout Bet Not Available`));
    //                 }
    //                 else
    //                     dispatch(setMsgForModal(`Cashout Bet for ${res.data?.value} EUR`));
    //                 dispatch(setCancelBetId(id));
    //                 dispatch(openModal());
    //             }
    //         );
    //
    //     } catch (error) {
    //         console.error('Error fetching league data:', error);
    //         return []; // Return an empty array in case of error
    //     }
    // };

    const generatePDF = () => {
        const element = document.getElementById(`testCotent-${id}`);


        const opt = {
            margin: 0,
            filename: getID(id),
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true, width: element.offsetWidth },  // set width to capture everything
            jsPDF: { unit: 'mm', format: [element.offsetWidth, element.offsetHeight], orientation: 'portrait' },
            pagebreak: { after: '.page-break' }
        };

        const worker = html2pdf().from(element).set(opt).toPdf().get("pdf").then(pdf => {
            const e = pdf.getNumberOfPages();
            if (e > 1){
                pdf.deletePage(2);
            }
            // Hide content again after capturing

        });
        worker.save();
    }

    const getDate = (date) => {
        return date.split(' ')[0]
    }
    const getTime = (date) => {
        return date.split(' ')[1]
    }
    const getID = (data) => {
        data = data.split('-')
        return data[data.length - 1]
    }


    return (
        <div className="flex flex-col text-[#ccc]">
            <div id="pdfContent" className="absolute top-0 left-0 overflow-hidden"
                 style={{ width: '78mm', height: '0' }}>
                <div id={`testCotent-${id}`} className="bg-white" style={{ width: '78mm', fontFamily: 'Anonymous Pro, monospace'}}>
                    <div className="p-4">


                        {/* Your HTML and Tailwind CSS design goes here */}
                        <div className="text-center m-[24px]"><img src={"https://storage.googleapis.com/betsite-imagedata/probetLOGOBW2.png"} className="w-40 mx-auto block"/></div>
                        <div className="bg-black h-[2px]"></div>
                        <div className="flex justify-between text-xs text-[10px] my-[1px] items-center">
                            <div>{userID}</div>
                            <div>{getDate(betDate)}</div>
                            <div>{getTime(betDate)}</div>
                        </div>
                        <div className="bg-black h-[2px]"></div>
                        <div className="w-full">
                            {el2.bets.map((data, index) => {
                                const {
                                    betID,
                                    homeTeam,
                                    awayTeam,
                                    msg1,
                                    msg2,
                                    oddVal,
                                    status,
                                    tu,
                                    BC,
                                    leagueName,
                                    mgid,
                                } = data;
                                return (
                                    <><div className="text-xs text-[10px] my-[1px]">
                                        <div className="my-[1px] font-semibold">
                                            <div>{homeTeam} - {awayTeam}</div>
                                        </div>
                                        <div className="my-[1px]">
                                            {betID[0] === "p" ? (
                                                <span className="text-xs text-[10px]">
                          {moment(BC, "YYYYMMDDHHmmss")
                              .add(1, "hour")
                              .format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                                            ) : (
                                                <span className="text-xs text-[10px]">
                          {BC !== ""
                              ? moment(BC, "YYYYMMDDHHmmss")
                              .add(1, "hour")
                              .format("YYYY-MM-DD HH:mm:ss") + "(L)"
                              : "Live Bet"}
                                                    {}
                        </span>
                                            )}
                                        </div>
                                        <div className="flex justify-between my-[1px]">
                                            <div className="flex flex-col divide-y-2">
                                                <div>
                          <span className="text-xs text-[10px]">
                            {translateText(msg1)}
                              &nbsp;
                          </span>
                                                    <span className="text-xs text-[10px]">
                            - &nbsp;
                                                        {translateText(msg2, false, false, true)}
                          </span>
                                                </div>
                                            </div>
                                            <div className="ml-2">
                                                {oddVal}
                                            </div>
                                        </div>

                                    </div>

                                    </>

                                );
                            })}
                        </div>
                        <div className="bg-black h-[2px]"></div>
                        <div className="my-[1px] flex justify-between text-xs text-[10px] font-semibold">
                            <div>{translateText('Total Odds')}</div>
                            <div>{winningChance}</div>
                        </div>
                        <div className="my-[1px] flex justify-between text-xs text-[10px] font-semibold">
                            <div>{translateText('Total Stake')}</div>
                            <div>EUR {betAmount}</div>
                        </div>
                        <div className="bg-black h-[2px]"></div>
                        <div className="my-[1px] flex justify-between text-xs text-[10px] font-semibold">
                            <div>{translateText('Max Return')}</div>
                            <div>EUR {winningAmount}</div>
                        </div>
                        <div className="my-12"></div>
                        <div className="text-center text-xs text-[10px] my-1">Good Luck!</div>
                        <div className="bg-black h-[0.5px]"></div>
                        <div className="text-center text-xs text-[10px] my-1">By takeover of this betslip the General Terms and Conditions of ProBet become valid. Please Gamble Responsibly. www.probet.club</div>
                        <div className="text-center text-2xl font-bold my-4">{getID(id)}</div>
                    </div>
                </div>
            </div>
            <div className={`flex bg-[#f6f6f6] text-black py-2 my-0.5 px-2 justify-between text-base rounded bg-opacity-40 bg-gradient-to-l to-[#888] ${
                el2.status === "WON"
                    ? "from-green-500"
                    : el2.status === "LOST"
                        ? "from-red-500"
                        : el2.status === "RESULT AWAITED" ? "from-yellow-500" : el2.status  === "CANCELLED" ? "from-blue-300" : "from-gray-500"
            }`}
            onClick={()=>{setSlipOpen(!slipOpen)}}
            >
                <div className="w-[10%] text-left">{el2.betAmount}</div>
                <div className="w-[80%] px-2 pl-16">
                    {el2.betDate.split(" ")[1]} - {el2.id.split("-")[4]}
                </div>
            </div>

            <div className={`flex flex-col rounded bg-[#545454] px-1 border   ${
                el2.status === "WON"
                    ? "border-green-500"
                    : el2.status === "LOST"
                        ? "border-red-500 "
                        : el2.status === "RESULT AWAITED" ? "border-yellow-500 " : el2.status  === "CANCELLED" ? "border-blue-300" : "border-gray-500 "
            } ${slipOpen ? '' : 'border-0'}`}>
                {slipOpen && <div className="py-2 px-2 text-base">
                    USER: <span className="font-bold">{userID}</span>
                </div>}
                {slipOpen && <h4 className="font-light text-center text-base my-2">
                    {systemValue === "1" || systemValue === "0" ? <>(
                        {systemValue === "1" ? <span>
                {translateText('You must win all the bets!')}
              </span> : <span>
                {translateText("Multi System Bet")}
              </span>}

                        )</> : (
                        <span>
                {translateText("You must win")}{" "}
                            <span className="font-medium">
                  {systemValue.split("/")[0]}{" "}
                </span>
                            {translateText("bets out of")}{" "}
                            <span className="font-medium">{systemValue.split("/")[1]}</span>
                .
              </span>
                    )}
                </h4>}
                {slipOpen && el2.bets.map((data) => {
                    return <MyBetSingleItem data={data} />
                })}

                {slipOpen && <div>
                    <div className="grid grid-cols-2 gap-2 my-2 text-sm px-1 text-[#ccc] font-semibold">
                        <div className="rounded bg-[#888] py-4 px-2">{translateText('Amount Won')}: <span className="font-bold">{el2.amountWon}</span></div>
                        <div className="rounded bg-[#888] py-4 px-2">{translateText('Bet Amount')}: <span className="font-bold">{el2.betAmount}</span></div>
                        <div className="rounded bg-[#888] py-4 px-2">{translateText('Winning Amount')}: <span className="font-bold">{el2.winningAmount}</span></div>
                        <div className="rounded bg-[#888] py-4 px-2">{translateText('Total Odds')}: <span className="font-bold">{el2.winningChance}</span></div>
                    </div>


                </div>}

                {
                    slipOpen && <div>
                        <div className="grid grid-cols-2 gap-2 my-2 px-1 text-[#ccc]">

                            <button
                                className="btn mx-2 py-2 bg-[#888] rounded"
                                onClick={generatePDF}
                            >
                                {translateText("Print Slip")}
                            </button>
                            {el2.status === "RESULT AWAITED" && <button
                                className="btn mx-2 py-2 bg-[#888] rounded"
                                // onClick={}
                            >
                                {translateText("Cash Out")}
                            </button>}
                        </div>
                    </div>
                }




            </div>

        </div>
    );
};

export default MyBetSingleSlip;
