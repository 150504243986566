import translationsEn from '../locales/en/translation.json';
import translationsEs from '../locales/sp/translation.json';
import translationsDe from '../locales/de/translation.json';
import translationsFr from '../locales/fr/translation.json';

const translations = {
    en: translationsEn,
    sp: translationsEs,
    de: translationsDe,
    fr: translationsFr
};

export const translateText = (text, isGrid=false, db = false, slip = false) => {

    if (!text)
        return text
    const y = localStorage.getItem("language");
    let formS;

    try {
        formS = y ? JSON.parse(y) : null;
    } catch (e) {
        formS = null;
    }

    if (isGrid){
        text = text + '_1'
    }
    if (slip){
        let parts = text.split(' - ');
        if (parts.length > 0) {
            parts[0] += '_1';
        }
        text = parts.join(' - ');
    }
    const l = formS ? formS : { value: 'sp', label: 'Shqip' };

    const translationDict = translations[l.value];
    const keys = Object.keys(translationDict).sort((a, b) => b.length - a.length);

    let translatedText = text;
    let startIndex = 0;

    while (text.length > 0) {
        let found = false;

        for (const key of keys) {
            const index = text.indexOf(key);

            if(index + key.length < text.length && key.length <= 2)
                if (text[index + key.length] && text[index + key.length] !== ' ')
                    continue
            if (text[index - 1] &&  text[index - 1] !== ' ')
                continue


            if (index !== -1) {
                translatedText = translatedText.replace(key, translationDict[key])
                text = text.replace(key, '')
                found = true;
                break;
            }
        }

        if (!found) {
            break;
        }
    }
    translatedText = translatedText.replace('_1', '')
    text = text.replace('_1', '')
    if (translatedText === text && db===false)
        return translateText(text, false, true)
    return translatedText.replace('_1', '');
};