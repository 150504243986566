import axios from 'axios';
import { getToken } from './services/auth';

const instance = axios.create({
    baseURL: 'https://slot.probet.club/',
});

instance.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = `Token ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
