import React, { useState } from "react";
import axios from "../axiosConfig";
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import {translateText} from "../services/translation";
const ChangePassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [reenterPassword, setReenterPassword] = useState("");
    const navigate = useNavigate()


    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== reenterPassword) {
            toast.error(translateText('Passwords do not match!'), {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setNewPassword('')
            setReenterPassword('')
            return;
        }
        if (reenterPassword === '') {
            toast.error(translateText('Enter a value!'), {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setNewPassword('')
            setReenterPassword('')
            return;
        }
        // console.log(userType)
        axios.post('/api/changePassword/', {  password: newPassword,
            reEnterPassword: reenterPassword, })
            .then((res) => {
                toast.success(translateText('Password Changed Successfully'), {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setNewPassword('')
                setReenterPassword('')
            })
            .catch((err) => {
                toast.error(translateText('Something went wrong'), {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setNewPassword('')
                setReenterPassword('')
            });
    };

    return (
        <section className=" text-[#ccc] bg-[#474747]  h-screen ">

            <div className="flex items-center justify-between pt-4 px-4">
                <div className="" onClick={()=>{navigate('/')}}>{'<'} {translateText('Back')}</div>
                <h1 className="flex-1 text-center text-lg xl:text-xl text-[#ccc] font-bold">
                    {translateText('Change Password')}
                </h1>
                <div className="mr-16"></div> {/* Empty div to take up space on the right */}
            </div>

            <div className="flex flex-col text-[#ccc] my-4">
                <div className="flex items-center justify-center font-bold text-[20px] h-[30px] w-full my-4">
                    {/*{translateText("Change Password")}*/}
                </div>
                <div className="flex items-center justify-center">
                    <form onSubmit={handleSubmit} className="flex flex-col text-black justify-center w-64">
                        <input
                            type="password"
                            placeholder={translateText("New Password")}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="mb-2 p-2 border border-[#ccc] rounded w-64"
                        />
                        <input
                            type="password"
                            placeholder={translateText("Re-enter Password")}
                            value={reenterPassword}
                            onChange={(e) => setReenterPassword(e.target.value)}
                            className="mb-2 p-2 border border-[#ccc] rounded w-64"
                        />
                        <button type="submit" className="bg-red-600 text-white p-2 rounded w-64">
                            {translateText("Submit")}
                        </button>
                    </form>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </section>
    );
};

export default ChangePassword;
