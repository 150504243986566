import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { login, callUserDate } from "../services/auth";
import { getDeviceId } from "../services/deviceID";
import { FiEye, FiEyeOff, FiX } from "react-icons/fi"; // Icons for show/hide and clear

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [savePassword, setSavePassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const deviceID = await getDeviceId();
        try {
            await login(username, password, deviceID);
            await callUserDate();
            navigate('/');
        } catch (err) {
            toast.error("Wrong credentials");
        }
    };

    const handleClearUsername = () => {
        setUsername("");
    };

    return (
        <div className="h-screen flex items-top justify-center bg-[#3e3e3e] p-4">
            <ToastContainer position="top-center" />
            <form
                className="bg-[#ddd] p-6  shadow-lg w-80 space-y-4 h-80"
                onSubmit={handleSubmit}
            >
                <div className="relative border border-[#aaa]">
                    <input
                        type="text"
                        placeholder="UserName"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="bg-[#f4f4f4] w-full p-3 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                    {username && (
                        <FiX
                            className="absolute right-3 top-3 text-gray-500 cursor-pointer"
                            onClick={handleClearUsername}
                        />
                    )}
                </div>
                <div className="relative border border-[#aaa]">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="bg-[#f4f4f4] w-full p-3 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                    <div
                        className="absolute right-3 top-4 text-gray-500 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                    </div>
                </div>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        checked={savePassword}
                        onChange={(e) => setSavePassword(e.target.checked)}
                        className="mr-2"
                    />
                    <label className="text-sm text-gray-600">Save Password</label>
                </div>
                <button
                    type="submit"
                    className="w-full bg-[#ffcc00] hover:bg-yellow-600 text-black py-2 font-medium"
                >
                    Login
                </button>
            </form>
        </div>
    );
};

export default Login;
