import React, { useState } from "react";
import axios from "../axiosConfig";
import Select from "react-select";
import {getUser} from "../services/auth";
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import {translateText} from "../services/translation";
const CreateUser = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userType, setUserType] = useState("user");
    const userData = getUser();
    const navigate = useNavigate()
    let options = []
    if (userData.is_superuser){
        options = [
            { value: 'user', label: 'User' },
            { value: 'manager', label: 'Manager' },
            { value: 'admin', label: 'Admin' },
            { value: 'superadmin', label: 'Super Admin' }
        ]
    }
    else if (userData.is_superadmin) {
        options = [
            { value: 'user', label: 'User' },
            { value: 'manager', label: 'Manager' },
            { value: 'admin', label: 'Admin' }]
    }
    else if (userData.is_admin) {
        options = [
            { value: 'user', label: 'User' },
            { value: 'manager', label: 'Manager' }]
    }
    else {
        options = [
            { value: 'user', label: 'User' }
        ]
    }

    const handleSubmit = (e) => {
        // console.log(userType)
        axios
            .post("https://slot.probet.club/api/register/", {
                userId: username,
                password: password,
                firstName: name,
                lastName: lastName,
                userType: userType,
            })
            .then((res) => {
                toast.success(translateText('User Created Successfully'), {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLastName("");
                setName("");
                setPassword("");
                setUsername("");
            })
            .catch((err) => {
                toast.error(translateText('UserID already exists / Information is missing'), {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    };

    return (
        <section className=" text-[#ccc] bg-[#474747]  h-screen ">

            <div className="flex items-center justify-between pt-4 px-4">
                <div className="" onClick={()=>{navigate('/')}}>{'<'} {translateText('Back')}</div>
                <h1 className="flex-1 text-center text-lg xl:text-xl text-[#ccc] font-bold">
                    {translateText('User Registration')}
                </h1>
                <div className="mr-16"></div> {/* Empty div to take up space on the right */}
            </div>

            <div className="flex flex-col space-y-4 px-8 xl:w-1/2 mx-auto py-10 text-white">
                <div className="flex flex-col space-y-2">
                    <label htmlFor="username" className="text-sm ">
                        {translateText('Username')}
                    </label>
                    <input
                        type="text"
                        value={username}
                        className="bg-[#676767] p-2 md:p-3 rounded"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-2">
                    <label htmlFor="password" className="text-sm ">
                        {translateText('Password')}
                    </label>
                    <input
                        type="password"
                        value={password}
                        className="bg-[#676767] p-2 md:p-3 rounded"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-2">
                    <label htmlFor="name" className="text-sm ">
                        {translateText('Name')}
                    </label>
                    <input
                        type="name"
                        value={name}
                        className="bg-[#676767] p-2 md:p-3 rounded"
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-2">
                    <label htmlFor="lastName" className="text-sm ">
                        {translateText('Surname')}
                    </label>
                    <input
                        type="lastName"
                        value={lastName}
                        className="bg-[#676767] p-2 md:p-3 rounded"
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-2">
                    <label htmlFor="usertype" className="text-sm ">
                        {translateText('User Type')}
                    </label>
                    <Select className='bg-[#676767] rounded text-white' options={options}
                            unstyled
                            isSearchable={false}
                            defaultValue={options[0]}
                            onChange={(newValue) => {setUserType(newValue.value)}}
                            classNames={{
                                singleValue: () => "bg-[#676767] px-2 rounded text-white",
                                valueContainer: () => "bg-[#676767]  rounded text-white",
                                placeholder: () => "bg-[#676767] px-2 rounded text-white",
                                control: () => "bg-[#676767] px-2 rounded text-white",
                                input: () => "bg-[#676767] px-2 rounded text-white",
                                menu: () => "bg-[#676767] p-2 my-2 rounded text-white",
                                option: () => "bg-[#676767] px-2 py-1 text-white",
                            }}
                    />
                </div>
                <button
                    className="font-medium text-[#ccc] bg-red-800 py-2 px-4"
                    onClick={handleSubmit}
                >
                    {translateText('Register')}
                </button>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </section>
    );
};

export default CreateUser;
