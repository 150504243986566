// src/utils/deviceId.js
import FingerprintJS from '@fingerprintjs/fingerprintjs';

let deviceIdPromise = null;

export async function getDeviceId() {
    if (!deviceIdPromise) {
        const fp = await FingerprintJS.load();
        deviceIdPromise = fp.get();
    }
    const result = await deviceIdPromise;
    return result.visitorId;
}