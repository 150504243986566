import axios from '../axiosConfig';

const TOKEN_KEY = 'user-token';

export const login = async (username, password, deviceID) => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('user-data');
    const response = await axios.post('/api/login/', { userId: username, password: password, device_id: deviceID });
    if (response.data.token) {
        localStorage.setItem(TOKEN_KEY, response.data.token);
    }
    return response.data;
};

export const callUserDate = async () => {
    try {
        const res = await axios.get("/api/user");
        if (res.status !== 200) {
            localStorage.removeItem(TOKEN_KEY, null);
            localStorage.removeItem('user-data');
            window.location.href = '/login';  // Correct the redirection
        } else {
            localStorage.setItem('user-data', JSON.stringify(res.data));
        }
    } catch (error) {
        localStorage.removeItem(TOKEN_KEY, null);
        localStorage.removeItem('user-data');
        window.location.href = '/login';  // Redirect on error
    }
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('user-data');

};

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
};

export const getUser = () => {
    let u = JSON.parse(localStorage.getItem('user-data'))
    if (!u.is_staff){
        logout()
        window.location.href = '/login';  // Redirect on error
    }
    return JSON.parse(localStorage.getItem('user-data'));
};

export const isAuthenticated = () => {
    return !!getToken();
};
