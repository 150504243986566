import {BrowserRouter, Routes, Route} from "react-router-dom";
import React from 'react';
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import CreateUser from "./pages/CreateUser";
import UserList from "./pages/UserList";
import Transfer from "./pages/Transfer";
import Report from "./pages/Report";
import MyBets from "./pages/MyBets";
import ChangePassword from "./pages/ChangePassword";


function App() {
  return (
      <BrowserRouter>
        <Routes>

          <Route path='/login' element={<Login/>}/>

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home/>} />
            <Route path="/createuser" element={<CreateUser/>}/>
            <Route path="/users" element={<UserList/>}/>
            <Route path="/transfer" element={<Transfer/>}/>
            <Route path="/report" element={<Report/>}/>
            <Route path="/bets" element={<MyBets/>}/>
            {/*<Route path="/prematch" element={<PreMatch/>}/>*/}
            {/*<Route path="/prematch" element={<PreMatch/>}/>*/}
            {/*<Route path="/lists" element={<PML2/>}/>*/}
            {/*<Route path="/coupon" element={<PML3/>}/>*/}
            {/*<Route path="/inplay" element={<InPlay/>} />*/}
            {/*<Route path="/event" element={<Event/>} />*/}
            {/*<Route path ="/dogs" element={<Dogs/>} />*/}
            {/*<Route path="/casino" element={<Casino/>}/>*/}
            {/*<Route path="/mybets" element={<MyBets/>}/>*/}
            {/*<Route path="/search" element={<Search/>}/>*/}
            <Route path="/changepassword" element={<ChangePassword/>}/>
            {/*<Route path="/changepassword" element={<ChangePassword/>}/>*/}
            {/*<Route path="/transactions" element={<TransactionHistory/>}/>*/}
            {/*<Route path="/reports" element={<Reports/>}/>*/}

          </Route>

        </Routes>
      </BrowserRouter>
  );
}

export default App;
