import {callUserDate, getUser, logout} from "../services/auth";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {translateText} from "../services/translation";

const Home = () => {
    const userData = getUser();
    const navigate = useNavigate()
    const logOut = () => {
        logout()
        navigate('/login')
    }
    useEffect(() => {
        callUserDate();
    }, []);
    const options = [
        { value: 'en', label: 'English' },
        { value: 'sp', label: 'Shqip' },
        { value: 'fr', label: 'French' },
        { value: 'de', label: 'German' },
    ];


    const y = localStorage.getItem("language");
    let formS;

    try {
        formS = y ? JSON.parse(y) : null;
    } catch (e) {
        formS = null;
    }
    const [language, setLanguage] = useState( formS ? formS : { value: 'sp', label: 'Shqip' })

    const handleChange = (selectedOption) => {
        localStorage.setItem("language", JSON.stringify(selectedOption));
        setLanguage(selectedOption)
    };
  return <div className="flex flex-col  justify-top bg-[#474747] text-[#ccc] h-screen w-screen items-center">

      <div className="flex flex-col m-4 items-center font-bold border border-[#888] w-60 rounded divide-y divide-[#888]">
          <div className="w-60 p-2 pt-4">UserID : {userData.userId}</div>
          <div className="w-60 p-2 pb-4">Balance : EUR {userData.userBalance}</div>
      </div>

        <div className="flex flex-col items-center justify-top w-60 ">
            <div className="flex items-center justify-center h-12 border border-[#888] rounded p-4 my-2 w-40 bg-red-800 mt-8" onClick={() => {navigate('/createuser')}}>{translateText('Create User')}</div>
            <div className="flex items-center justify-center h-12 border border-[#888] rounded p-4 my-2 w-40 bg-red-800 " onClick={() => {navigate('/users')}}>{translateText('User List')}</div>
            <div className="flex items-center justify-center h-12 border border-[#888] rounded p-4 my-2  w-40 bg-red-800" onClick={() => {navigate('/report')}}>{translateText('Finance Report')}</div>
            <div className="flex items-center justify-center h-12 border border-[#888] rounded p-4 my-2  w-40 bg-red-800" onClick={() => {navigate('/bets')}}>{translateText('Bet Report')}</div>
            <div className="flex items-center justify-center h-12 border border-[#888] rounded p-4 my-2  w-40 bg-red-800" onClick={() => {navigate('/transfer')}}>{translateText('Transfer History')}</div>
            <div className="flex items-center justify-center h-12 border border-[#888] rounded p-4 my-2  w-40 bg-red-800" onClick={() => {navigate('/changepassword')}}>{translateText('Change Password')}</div>
            <div className="flex items-center justify-center h-12 border border-[#888] rounded p-4 my-2  w-40 bg-red-800" onClick={logOut}>{translateText('Logout')}</div>
        </div>

      <div className="flex w-full my-6 mt-12 justify-center items-center">
          <Select
              unstyled
              isSearchable={false}
              className="w-52 border-0 text-[12px]"
              value={language}

              options={options}
              onChange={handleChange}
              menuPlacement="top"

              components={{ Input: () => null }} // Prevent the input from rendering to avoid keyboard opening

              styles={{
                  control: (provided, state) => ({
                      ...provided,
                      fontSize: '12px',
                      backgroundColor: '#ccc',
                      borderColor: '#888',
                      color: '#444',
                      boxShadow: state.isFocused ? '0 0 0 1px #888' : provided.boxShadow,
                      '&:hover': {
                          borderColor: '#888',
                      },
                      padding: '2px 10px'
                  }),
                  menu: (provided) => ({
                      ...provided,
                      backgroundColor: '#ddd',
                      borderColor: '#888',
                      color: '#444',
                      padding: '2px 10px'
                  }),
                  option: (provided, state) => ({
                      ...provided,
                      fontSize: '12px',
                      padding: '5px',
                      backgroundColor: state.isSelected ? '#ddd' : provided.backgroundColor,
                      color: '#444',
                      '&:hover': {
                          backgroundColor: '#ccc',
                      },
                  }),
                  singleValue: (provided) => ({
                      ...provided,
                      color: '#444',
                  }),
                  placeholder: (provided) => ({
                      ...provided,
                      color: '#444',
                  }),
                  dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#444',
                  }),
              }}
          />
      </div>

  </div>
}

export default Home