export const PersonIcon = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" id="Слой_1" data-name="Слой 1" width="20"
                 height="20" viewBox="0 0 480.15 555.51">
        <defs>
        </defs>
        <path fill="#e90d00"
              d="M915.24,821.75c-49.54-85.9-121.8-134.06-220.89-147.23l-8-1.06,3.88-7c26.18-47.49,50.6-89.91,84.33-128.14C813.68,494,860.59,461.51,918,439l2-.79,2,.79c65.89,25.91,118.43,58,156.17,95.3,38.78,38.37,63.06,83.37,72.16,133.77l1,5.72-5.76.72c-101.7,12.75-173.92,60.87-220.78,147.1l-4.69,8.63Z"
              transform="translate(-677.67 -286)"/>
        <path
            fill="#fff"
            d="M920,444.14c129.64,51,206.87,125,224.93,224.93C1038.37,682.43,965.64,735,920,819c-47.84-83-120.28-136-224.93-149.93C743.78,580.71,794.41,493.33,920,444.14m0-11.82-4,1.58c-58.24,22.81-105.85,55.76-145.53,100.74-34.11,38.66-58.68,81.35-85,129.12l-7.77,14.09,16,2.12c97.29,12.93,168.22,60.21,216.85,144.53l9.82,17,9.38-17.26c46-84.56,116.81-131.75,216.63-144.27l11.52-1.44-2.07-11.43c-9.31-51.53-34.11-97.52-73.71-136.7-38.26-37.86-91.43-70.33-158-96.51l-4-1.58Z"
            transform="translate(-677.67 -286)"/>
        <circle fill="#e90d00" cx="242.33" cy="118" r="112.5"/>
        <path fill="#fff"
              d="M920,297A107,107,0,1,1,813,404,107,107,0,0,1,920,297m0-11a118,118,0,1,0,83.44,34.56A117.22,117.22,0,0,0,920,286Z"
              transform="translate(-677.67 -286)"/>
    </svg>)
}