import React, {useState, useEffect} from "react";
import axios from "../axiosConfig";
import {useNavigate} from "react-router-dom";
import {PersonIcon} from "../icons";
import {MdClose} from "react-icons/md";
import {IconContext} from "react-icons";
import {getUser} from "../services/auth";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import {translateText} from "../services/translation";

const UserItem = ({userData, modal}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div>
            <div className='flex flex-col'>
                <div className={isExpanded ? 'flex border-b-2 border-[#888] bg-red-300 text-[#474747]' : 'flex border-b-2 border-[#888]'}>

                    {userData.is_staff && userData.children.length !== 0 ? <button
                        className="flex items-center justify-center w-1/6 px-2 overflow-hidden text-ellipsis py-2 xl:py-4"
                        onClick={toggleExpansion}>{isExpanded ? '^' : '⌄'}</button> : <span className='w-1/6'></span>}
                    <span
                        className="flex items-center justify-center w-1/3 px-2 overflow-hidden text-ellipsis py-2 xl:py-4">{userData.userId}</span>
                    <span
                        className="flex items-center justify-end w-1/3 px-2 overflow-hidden text-ellipsis py-2 xl:py-4">{userData.userBalance}</span>
                    <span
                        className="flex items-center justify-center w-1/6 px-2 overflow-hidden text-ellipsis py-2 xl:py-4"
                        onClick={() => modal(userData)}><PersonIcon/></span>
                </div>

                {isExpanded && (
                    <div className="flex flex-col">
                        {userData.children.map((child, index) => (
                            <UserItem key={index} userData={child} modal={modal}/>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};


const UserItemSearch = ({userData, modal}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div>
            <div className='flex flex-col'>
                <div className={isExpanded ? 'flex border-b-2 bg-red-300 text-[#474747]  border-[#888]' : 'flex border-b-2 border-[#888]'}>

                    <span
                        className="flex items-center justify-center w-1/3 px-2 overflow-hidden text-ellipsis py-2 xl:py-4">{userData.userId}</span>
                    <span
                        className="flex items-center justify-end w-1/3 px-2 overflow-hidden text-ellipsis py-2 xl:py-4">{userData.userBalance}</span>
                    <span
                        className="flex items-center justify-center w-1/6 px-2 overflow-hidden text-ellipsis py-2 xl:py-4"
                        onClick={() => modal(userData)}><PersonIcon/></span>
                </div>

            </div>
        </div>
    );
};


const Modal = ({
                   isOpen,
                   onClose,
                   onApiCall,
                   onToggleChangeSports,
                   onToggleChangeCasino,
                   onToggleChangeVirtual,
                   onToggleChangeCommission,
                   modalUserData,
                   isToggledSports,
                   isToggledCasino,
                   isToggledVirtual,
                   isToggledCommission,
                   selectedOption,
                   handleOptionChange,
                   setAmount,
                   amount, winLimit, setWinLimit
               }) => {
    const userLevel = getUser();
    const [dropMini, setDropMini] = useState(0.00)
    const [dropMajor, setDropMajor] = useState(0.00)
    const [dropMega, setDropMega] = useState(0.00)
    return (

        <div className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 bg-[#000] bg-opacity-40`}>
            <div className="bg-[#363636] rounded-lg p-6 m-auto mt-24 w-3/4">
                <button onClick={onClose} className="text-[#ccc] float-right text-2xl font-bold">&times;</button>
                <h2 className="text-xl font-semibold mb-4">{modalUserData.userId}</h2>
                <p>User Balance : {modalUserData.userBalance}</p>
                {userLevel.isCasinoAllowed && <div className='flex justify-between items-center'>
                    <label className="mr-4 mt-5">{translateText('Casino')}</label>
                    <label htmlFor="casino" className="inline-flex relative items-center cursor-pointer mt-5">

                        <input key='casino' type="checkbox" id="casino" className="sr-only" checked={isToggledCasino}
                               onChange={() => {
                                   onToggleChangeCasino()
                               }}/>
                        <div
                            className={`block w-10 h-5 rounded-full ${isToggledCasino ? 'bg-green-500' : 'bg-gray-600'}`}></div>
                        <div
                            className={`dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition ${isToggledCasino ? 'translate-x-5' : ''}`}></div>
                    </label>
                </div>}

                {
                    userLevel.isBetAllowed && <div className='flex justify-between items-center'>
                        <label className="mr-4 mt-5">{translateText('Sports')}</label>
                        <label htmlFor="sports" className="inline-flex relative items-center cursor-pointer mt-5">
                            <input key='sports' type="checkbox" id="sports" className="sr-only" checked={isToggledSports}
                                   onChange={() => {
                                       onToggleChangeSports()
                                   }}/>
                            <div
                                className={`block w-10 h-5 rounded-full ${isToggledSports ? 'bg-green-500' : 'bg-gray-600'}`}></div>
                            <div
                                className={`dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition ${isToggledSports ? 'translate-x-5' : ''}`}></div>
                        </label>
                    </div>
                }

                {
                    userLevel.isVirtualAllowed && <div className='flex justify-between items-center'>
                        <label className="mr-4 mt-5">{translateText('Virtual')}</label>
                        <label htmlFor="virtual" className="inline-flex relative items-center cursor-pointer mt-5">
                            <input key='virtual' type="checkbox" id="virtual" className="sr-only" checked={isToggledVirtual}
                                   onChange={() => {
                                       onToggleChangeVirtual()
                                   }}/>
                            <div
                                className={`block w-10 h-5 rounded-full ${isToggledVirtual ? 'bg-green-500' : 'bg-gray-600'}`}></div>
                            <div
                                className={`dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition ${isToggledVirtual ? 'translate-x-5' : ''}`}></div>
                        </label>
                    </div>
                }

                {userLevel.is_superadmin && (<div className='flex justify-between items-center'>
                    <label className="mr-4 mt-5">{translateText('Commission')}</label>
                    <label htmlFor="commission" className="inline-flex relative items-center cursor-pointer mt-5">
                        <input key='commission' type="checkbox" id="commission" className="sr-only"
                               checked={isToggledCommission}
                               onChange={() => {
                                   onToggleChangeCommission()
                               }}/>
                        <div
                            className={`block w-10 h-5 rounded-full ${isToggledCommission ? 'bg-green-500' : 'bg-gray-600'}`}></div>
                        <div
                            className={`dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition ${isToggledCommission ? 'translate-x-5' : ''}`}></div>
                    </label>
                </div>)}


                <div className="flex flex-col items-center justify-center">
                    <div className="mt-3">
                        <input type="radio" id="deposit" value='deposit' name="radio"
                               checked={selectedOption === 'deposit'}
                               onChange={handleOptionChange} className="mr-2"/>
                        <label htmlFor="deposit" className="mr-4">{translateText('Deposit')}</label>
                        <input type="radio" id="withdraw" name="radio" value='withdraw'
                               checked={selectedOption === 'withdraw'}
                               onChange={handleOptionChange} className="mr-2"/>
                        <label htmlFor="withdraw">{translateText('Withdraw')}</label>
                    </div>
                    <input type="number" placeholder={translateText('Deposit/Withdraw')} className="mt-3 p-2 border rounded w-5/6 text-[#474747]"
                           onChange={(e) => setAmount(e.target.value)}
                           value={amount}/>
                    <button onClick={() => {
                        onApiCall('transfer')
                    }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold text-center px-4 rounded mt-3 w-3/4 h-8">
                        Submit
                    </button>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <button onClick={() => {
                        onApiCall('showbets')
                    }}
                            className="bg-red-500 hover:bg-red-700 text-white font-semibold text-center px-4 rounded mt-3 w-3/4 h-8">
                        {translateText('Show Transfers')}
                    </button>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <button onClick={() => {
                        onApiCall('reset')
                    }}
                            className="bg-red-500 hover:bg-red-700 text-white font-semibold text-center px-4 rounded mt-3 w-3/4 h-8">
                        {translateText('Reset Password')}
                    </button>
                </div>
                {userLevel.is_superuser && (<div className="flex flex-col items-center justify-center">

                    <input type="number" placeholder="Max Win Limit" className="mt-3 p-2 border rounded w-5/6 text-[#474747]"
                           onChange={(e) => setWinLimit(e.target.value)}
                           value={winLimit}/>
                    <button onClick={() => {
                        onApiCall('maxWinLimit')
                    }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold text-center px-4 rounded mt-3 w-3/4 h-8">
                        Set Win
                    </button>
                </div>)}
                {userLevel.is_superuser && (<div className="flex flex-col items-center justify-center">

                    <input type="number" placeholder="Max Win Limit" className="mt-3 p-2 border rounded w-5/6 text-[#474747]"
                           onChange={(e) => setDropMini(e.target.value)}
                           value={dropMini}/>
                    <button onClick={() => {
                        onApiCall('dropMini', dropMini)
                    }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold text-center px-4 rounded mt-3 w-3/4 h-8">
                        Drop Mini
                    </button>
                </div>)}
                {userLevel.is_superuser && (<div className="flex flex-col items-center justify-center">

                    <input type="number" placeholder="Max Win Limit" className="mt-3 p-2 border rounded w-5/6 text-[#474747]"
                           onChange={(e) => setDropMajor(e.target.value)}
                           value={dropMajor}/>
                    <button onClick={() => {
                        onApiCall('dropMajor', dropMajor)
                    }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold text-center px-4 rounded mt-3 w-3/4 h-8">
                        Drop Major
                    </button>
                </div>)}
                {userLevel.is_superuser && (<div className="flex flex-col items-center justify-center">

                    <input type="number" placeholder="Max Win Limit" className="mt-3 p-2 border rounded w-5/6 text-[#474747]"
                           onChange={(e) => setDropMega(e.target.value)}
                           value={dropMega}/>
                    <button onClick={() => {
                        onApiCall('dropMega', dropMega)
                    }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold text-center px-4 rounded mt-3 w-3/4 h-8">
                        Drop Mega
                    </button>
                </div>)}
            </div>
        </div>
    );
};

const UserList = () => {

    const [userList, setUserList] = useState([]);
    const [userListSearch, setUserListSearch] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalUserData, setModalUserData] = useState('')
    const [isToggledCasino, setIsToggledCasino] = useState(true)
    const [isToggledVirtual, setIsToggledVirtual] = useState(true)
    const [isToggledSports, setIsToggledSports] = useState(true)
    const [isToggledCommission, setIsToggledCommission] = useState(true)
    // Initialize the state with the default value
    const [selectedOption, setSelectedOption] = useState('deposit');
    const [amount, setAmount] = useState("");
    const [winLimit, setWinLimit] = useState("");
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [searchActive, setSearchActive] = useState(false);

    // Function to update the state when a radio button is selected
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleOpenModal = (userData) => {
        // console.log(userData)
        setSelectedOption('deposit')
        setIsModalOpen(true);
        setModalUserData(userData);

        setIsToggledCasino(userData.isCasinoAllowed)
        setIsToggledVirtual(userData.isVirtualAllowed)
        setIsToggledSports(userData.isBetAllowed)
        setIsToggledCommission(userData.isCommissionEnabled)
        setWinLimit(userData.maxWinLimit)
    }
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalUserData('');
    }
    const handleApiCall = (bttn, amountJP=0) => {
        // fetchData();
        if (bttn === "reset") {
            axios
                .post("https://slot.probet.club/api/userSettings/", {
                    userID: modalUserData.userId,
                    action: 'reset_password',

                })
                .then((res) => {
                    // setUserList(res.data);
                    toast.success(translateText('Password Reset Successfully to : 123456'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                })
                .catch((err) => {
                    throw err;
                });
        }
        if (bttn === "transfer" && amount !== "") {

            axios
                .post("https://slot.probet.club/api/userSettings/", {
                    userID: modalUserData.userId,
                    action: 'transfer',
                    transferType: selectedOption,
                    amount: amount
                })
                .then((res) => {
                    setUserList(res.data);
                    toast.success(translateText('Transfer Success'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setAmount("");
                })
                .catch((err) => {
                    toast.error(translateText('Unauthorized Operation'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        }
        if (bttn === "dropMini" && amountJP !== 0) {

            axios
                .post("https://slot.probet.club/api/dropjp", {
                    userID: modalUserData.userId,
                    action: 'dropMini',
                    transferType: selectedOption,
                    amount: amountJP
                })
                .then((res) => {
                    setUserList(res.data);
                    toast.success(translateText('Transfer Success'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setAmount("");
                })
                .catch((err) => {
                    toast.error(translateText('Unauthorized Operation'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        }
        if (bttn === "dropMajor" && amountJP !== 0) {

            axios
                .post("https://slot.probet.club/api/dropjp", {
                    userID: modalUserData.userId,
                    action: 'dropMajor',
                    transferType: selectedOption,
                    amount: amountJP
                })
                .then((res) => {
                    setUserList(res.data);
                    toast.success(translateText('Transfer Success'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setAmount("");
                })
                .catch((err) => {
                    toast.error(translateText('Unauthorized Operation'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        }
        if (bttn === "dropMega" && amountJP !== 0) {

            axios
                .post("https://slot.probet.club/api/dropjp", {
                    userID: modalUserData.userId,
                    action: 'dropMega',
                    transferType: selectedOption,
                    amount: amountJP
                })
                .then((res) => {
                    setUserList(res.data);
                    toast.success(translateText('Transfer Success'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setAmount("");
                })
                .catch((err) => {
                    toast.error(translateText('Unauthorized Operation'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        }
        if (bttn === "maxWinLimit") {

            axios
                .post("https://slot.probet.club/api/userSettings/", {
                    userID: modalUserData.userId,
                    action: 'maxWinLimit',
                    amount: winLimit
                })
                .then((res) => {
                    setUserList(res.data);
                    toast.success(translateText('Change Success'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
                .catch((err) => {
                    toast.error(translateText('Unauthorized Operation'), {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        }
        if (bttn === 'showbets') {
            navigate(
                `/transfer?id=${modalUserData.userId}`
            );
        }
        // Implement API Call
    };
    const handleToggleChangeCasino = (e) => {
        setIsToggledCasino(!isToggledCasino)

        axios
            .post("https://slot.probet.club/api/userSettings/", {
                userID: modalUserData.userId,
                action: 'casino',
                actionState: !isToggledCasino ? 't' : 'f',
            })
            .then((res) => {
                setUserList(res.data);
            })
            .catch((err) => {
                throw err;
            });
        // Implement Toggle Change Logic
    };
    const handleToggleChangeVirtual = (e) => {
        setIsToggledVirtual(!isToggledVirtual)

        axios
            .post("https://slot.probet.club/api/userSettings/", {
                userID: modalUserData.userId,
                action: 'virtual',
                actionState: !isToggledVirtual ? 't' : 'f',
            })
            .then((res) => {
                setUserList(res.data);
            })
            .catch((err) => {
                throw err;
            });
        // Implement Toggle Change Logic
    };
    const handleToggleChangeSports = (e) => {
        setIsToggledSports(!isToggledSports)
        // Implement Toggle Change Logic

        axios
            .post("https://slot.probet.club/api/userSettings/", {
                userID: modalUserData.userId,
                action: 'betting',
                actionState: !isToggledSports ? 't' : 'f',
            })
            .then((res) => {
                setUserList(res.data);
            })
            .catch((err) => {
                throw err;
            });
    };

    const handleToggleChangeCommission = (e) => {
        setIsToggledCommission(!isToggledCommission)
        // Implement Toggle Change Logic

        axios
            .post("https://slot.probet.club/api/userSettings/", {
                userID: modalUserData.userId,
                action: 'commission',
                actionState: !isToggledCommission ? 't' : 'f',
            })
            .then((res) => {
                setUserList(res.data);
            })
            .catch((err) => {
                throw err;
            });
    };

    const fetchData = () => {

        axios
            .get("https://slot.probet.club/api/tree/")
            .then((res) => {
                setUserList(res.data);
            })
            .catch((err) => {
                throw err;
            });
    };
    const handleChange = (e) => {
        const text = e.target.value;
        setSearchText(text);
    };
    const handleChangeEmpty = (e) => {
        setSearchText('');
    };
    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        let l = []
        if (searchText === '')
            setUserListSearch([])
        if (userList.length !== 0) {

            userList.children.map((user, index) => {
                if (user.userId.toLowerCase().includes(searchText.toLowerCase())) {
                    l.push(user)
                }
                if (user.is_staff) {
                    user.children.map((user1, index) => {
                        if (user1.userId.toLowerCase().includes(searchText.toLowerCase())) {
                            l.push(user1)
                        }
                        if (user1.is_staff) {
                            user1.children.map((user2, index) => {
                                if (user2.userId.toLowerCase().includes(searchText.toLowerCase())) {
                                    l.push(user2)
                                }
                                if (user2.is_staff) {
                                    user2.children.map((user3, index) => {
                                        if (user3.userId.toLowerCase().includes(searchText.toLowerCase())) {
                                            l.push(user3)
                                        }
                                        if (user3.is_staff) {
                                            user3.children.map((user4, index) => {
                                                if (user4.userId.toLowerCase().includes(searchText.toLowerCase())) {
                                                    l.push(user4)
                                                }

                                            })
                                        }

                                    })
                                }

                            })
                        }

                    })
                }

            })
            setUserListSearch(l)
        }
    }, [searchText]);

    return (
        <div className=" w-full overflow-y-scroll h-screen bg-[#474747] text-[#ccc]"
            // onScroll={() => {enterFullScreen()}}
        >
            <div className="bg-red-800 flex justify-between items-center p-2">


                <div className="relative flex  w-full items-center">
                    <div className="text-[#ccc] px-2" onClick={() => {navigate('/')}}>{'< Back'}</div>
                    <input
                        type="text"
                        className="bg-[#282621] text-[#ccc] rounded pl-4 pr-10 py-2"
                        placeholder="Search User"
                        onChange={handleChange}
                        value={searchText}

                    />
                    <div onClick={() => handleChangeEmpty()}>
                        <IconContext.Provider value={{color: "gray", size: "2.5em", className: "ml-8"}}>
                            <MdClose/>
                        </IconContext.Provider>
                    </div>


                </div>
            </div>
            <div className="flex justify-between text-sm sm:text-base lg:text-base xl:text-lg  top-4 text-[#474747]">
                <span
                    className="flex items-center justify-center w-1/6 bg-[#E6E6E6] px-2 overflow-hidden text-ellipsis py-2 xl:py-4 "
                    key={'0'}
                >
                            </span>
                <span
                    className="flex items-center justify-center w-1/3 bg-[#E6E6E6] px-2 overflow-hidden text-ellipsis py-2 xl:py-4 "
                    key={'1'}
                >
                              User ID
                            </span>
                <span
                    className="flex items-center justify-center w-1/3 bg-[#E6E6E6] px-2 overflow-hidden text-ellipsis py-2 xl:py-4"
                    key={'2'}
                >
                              Balance
                            </span>
                <span
                    className="flex items-center justify-center
                    w-1/6 bg-[#E6E6E6] px-2 overflow-hidden text-ellipsis py-2 xl:py-4 "
                    key={'3'}
                >
                            </span>

            </div>
            {/*table*/}
            {
                searchText === '' && <div

                    className="w-full  py-4 text-sm sm:text-base">
                    {userList.length !== 0 && userList.children.map((user, index) => (
                        <UserItem key={index} userData={user} modal={handleOpenModal}/>
                    ))}

                </div>
            }
            {
                searchText !== '' && <div

                    className="w-full  py-4 text-sm sm:text-base">
                    {userListSearch.length !== 0 && userListSearch.map((user, index) => (
                        <UserItemSearch key={index} userData={user} modal={handleOpenModal}/>
                    ))}

                </div>
            }
            <div className="m-16"></div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Modal isOpen={isModalOpen}
                   onClose={handleCloseModal}
                   onApiCall={handleApiCall}
                   onToggleChangeSports={handleToggleChangeSports}
                   onToggleChangeCasino={handleToggleChangeCasino}
                   onToggleChangeVirtual={handleToggleChangeVirtual}
                   onToggleChangeCommission={handleToggleChangeCommission}
                   modalUserData={modalUserData}
                   isToggledSports={isToggledSports}
                   isToggledCasino={isToggledCasino}
                   isToggledVirtual={isToggledVirtual}
                   isToggledCommission={isToggledCommission}
                   selectedOption={selectedOption}
                   handleOptionChange={handleOptionChange}
                   setAmount={setAmount}
                   amount={amount}
                   winLimit={winLimit}
                   setWinLimit={setWinLimit}/>

        </div>
    );
};

export default UserList;
